import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

import type { SocketMessage } from 'lib/socket/types';
import type { Block } from 'types/api/block';

import { route } from 'nextjs-routes';

import config from 'configs/app';
import useApiQuery, { getResourceKey } from 'lib/api/useApiQuery';
import useIsMobile from 'lib/hooks/useIsMobile';
import useSocketChannel from 'lib/socket/useSocketChannel';
import useSocketMessage from 'lib/socket/useSocketMessage';
import { BLOCK } from 'stubs/block';
import LinkInternal from 'ui/shared/links/LinkInternal';

import LatestBlocksItem from './LatestBlocksItem';

const LatestBlocks = () => {
  const isMobile = useIsMobile();
  // const blocksMaxCount = isMobile ? 2 : 3;
  let blocksMaxCount: number;
  if (
    config.features.rollup.isEnabled ||
    config.UI.views.block.hiddenFields?.total_reward
  ) {
    blocksMaxCount = isMobile ? 4 : 5;
  } else {
    blocksMaxCount = isMobile ? 2 : 3;
  }
  const { data, isPlaceholderData, isError } = useApiQuery('homepage_blocks', {
    queryOptions: {
      placeholderData: Array(blocksMaxCount).fill(BLOCK),
    },
  });

  const queryClient = useQueryClient();

  const handleNewBlockMessage: SocketMessage.NewBlock['handler'] =
    React.useCallback(
      (payload) => {
        queryClient.setQueryData(
          getResourceKey('homepage_blocks'),
          (prevData: Array<Block> | undefined) => {
            const newData = prevData ? [ ...prevData ] : [];

            if (
              newData.some((block) => block.height === payload.block.height)
            ) {
              return newData;
            }

            return [ payload.block, ...newData ]
              .sort((b1, b2) => b2.height - b1.height)
              .slice(0, blocksMaxCount);
          },
        );
      },
      [ queryClient, blocksMaxCount ],
    );

  const channel = useSocketChannel({
    topic: 'blocks:new_block',
    isDisabled: isPlaceholderData || isError,
  });
  useSocketMessage({
    channel,
    event: 'new_block',
    handler: handleNewBlockMessage,
  });

  let content;

  if (isError) {
    content = <Text>No data. Please reload page.</Text>;
  }

  if (data) {
    const dataToShow = data.slice(0, blocksMaxCount);

    content = (
      <>
        <VStack
          spacing={ 2 }
          mb={ 3 }
          overflow="hidden"
          alignItems="stretch"
          className="divide-y"
        >
          <AnimatePresence initial={ false }>
            { dataToShow.map((block, index) => (
              <LatestBlocksItem
                key={ block.height + (isPlaceholderData ? String(index) : '') }
                block={ block }
                isLoading={ isPlaceholderData }
              />
            )) }
          </AnimatePresence>
        </VStack>
        <Flex justifyContent="center">
          <LinkInternal
            fontSize="sm"
            href={ route({ pathname: '/blocks' }) }
            backgroundColor="#eeeeee"
            borderRadius="full"
            color="#004A78"
            px="20px"
            py="5px"
            fontWeight="600"
            fontStyle="14px"
            mb="20px"
            _hover={{ textDecoration: 'none', backgroundColor: '#dddddd' }}
          >
            View all blocks
          </LinkInternal>
        </Flex>
      </>
    );
  }

  return (
    <Box
      width={{ base: '100%', lg: '280px' }}
      flexShrink={ 0 }
      className="white-shadow-container"
    >
      <Heading
        as="h4"
        size="sm"
        color="#004A78"
        borderBottom="1px solid"
        borderBottomColor="#38CEEB"
        padding="20px"
        fontSize="20px"
        fontWeight="600"
      >
        Latest blocks
      </Heading>
      <Box mt={ 3 }>{ content }</Box>
    </Box>
  );
};

export default LatestBlocks;
